define("ember-power-calendar/templates/components/power-calendar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (assign this.publicAPI (hash
    Nav=(component (ensure-safe-component this.navComponent) calendar=(readonly this.publicAPI))
    Days=(component (ensure-safe-component this.daysComponent) calendar=(readonly this.publicAPI))
  )) as |calendar|}}
    {{#let (element this.tagWithDefault) as |Tag|}}
      <Tag class="ember-power-calendar" ...attributes id={{calendar.uniqueId}}>
        {{#if (has-block)}}
          {{yield calendar}}
        {{else}}
          <calendar.Nav/>
          <calendar.Days/>
        {{/if}}
      </Tag>
    {{/let}}
  {{/let}}
  
  */
  {
    "id": "W1IKmLXg",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"publicAPI\"]],[28,[37,2],null,[[\"Nav\",\"Days\"],[[50,[28,[37,4],[[30,0,[\"navComponent\"]]],null],0,null,[[\"calendar\"],[[28,[37,5],[[30,0,[\"publicAPI\"]]],null]]]],[50,[28,[37,4],[[30,0,[\"daysComponent\"]]],null],0,null,[[\"calendar\"],[[28,[37,5],[[30,0,[\"publicAPI\"]]],null]]]]]]]],null]],[[[44,[[28,[37,6],[[30,0,[\"tagWithDefault\"]]],null]],[[[1,\"    \"],[8,[30,2],[[24,0,\"ember-power-calendar\"],[17,3],[16,1,[30,1,[\"uniqueId\"]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"        \"],[18,4,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,1,[\"Nav\"]],null,null,null],[1,\"\\n        \"],[8,[30,1,[\"Days\"]],null,null,null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]]]],[1]]]],[\"calendar\",\"Tag\",\"&attrs\",\"&default\"],false,[\"let\",\"assign\",\"hash\",\"component\",\"ensure-safe-component\",\"readonly\",\"element\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});